exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-app-modernization-service-tsx": () => import("./../../../src/pages/app-modernization-service.tsx" /* webpackChunkName: "component---src-pages-app-modernization-service-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-custom-application-development-service-tsx": () => import("./../../../src/pages/custom-application-development-service.tsx" /* webpackChunkName: "component---src-pages-custom-application-development-service-tsx" */),
  "component---src-pages-dedicated-development-team-tsx": () => import("./../../../src/pages/dedicated-development-team.tsx" /* webpackChunkName: "component---src-pages-dedicated-development-team-tsx" */),
  "component---src-pages-hire-rails-developers-tsx": () => import("./../../../src/pages/hire-rails-developers.tsx" /* webpackChunkName: "component---src-pages-hire-rails-developers-tsx" */),
  "component---src-pages-hire-ruby-on-rails-developer-tsx": () => import("./../../../src/pages/hire-ruby-on-rails-developer.tsx" /* webpackChunkName: "component---src-pages-hire-ruby-on-rails-developer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-rails-calculator-tsx": () => import("./../../../src/pages/rails-calculator.tsx" /* webpackChunkName: "component---src-pages-rails-calculator-tsx" */),
  "component---src-pages-rails-upgrade-services-tsx": () => import("./../../../src/pages/rails-upgrade-services.tsx" /* webpackChunkName: "component---src-pages-rails-upgrade-services-tsx" */),
  "component---src-pages-ruby-on-rails-upgrade-services-tsx": () => import("./../../../src/pages/ruby-on-rails-upgrade-services.tsx" /* webpackChunkName: "component---src-pages-ruby-on-rails-upgrade-services-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */)
}

